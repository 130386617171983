import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithRef, useCallback, useState } from 'react';
import {
  formatAppName,
  formatDateAndTime,
  formatDuration,
  formatShortUUid,
} from './utilities';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Events } from './Events';
import { useTranslation } from 'react-i18next';
import { Session as ISession } from 'hive-analytics-react';

interface SummaryItemProps {
  label: string;
  value?: string | number;
}

function SummaryItem({
  label,
  value,
}: PropsWithRef<SummaryItemProps>): JSX.Element {
  return (
    <Stack flexGrow="1" flexBasis={0} className="summary-item">
      <Typography variant="body1">{label}</Typography>
      <Typography variant="body2">{value || ''}</Typography>
    </Stack>
  );
}

export interface SessionProps {
  session: ISession;
}

export function Session({ session }: PropsWithRef<SessionProps>): JSX.Element {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const onExpanded = useCallback(
    (_event: any, newExpanded: boolean) => setExpanded(newExpanded),
    []
  );

  return (
    <Accordion elevation={0} id={session.id || ''} onChange={onExpanded}>
      <AccordionSummary expandIcon={<ChevronRightIcon fontSize="large" />}>
        <Stack width="100%" direction="row">
          <SummaryItem
            label={t('content.metrics.session.summary.session_id')}
            value={formatShortUUid(session?.id)}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.user_id')}
            value={formatShortUUid(session?.userId)}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.start')}
            value={formatDateAndTime(session.startTime)}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.duration')}
            value={formatDuration(session?.duration)}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.app')}
            value={formatAppName(t, session)}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.version')}
            value={session?.appVersion}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.platform')}
            value={session?.platform}
          />
          <SummaryItem
            label={t('content.metrics.session.summary.browser')}
            value={session?.browser}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        {expanded && <Events sessionId={session.id} />}
      </AccordionDetails>
    </Accordion>
  );
}
