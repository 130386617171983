import {
  Stack,
  Typography,
  Link,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useState, useCallback, PropsWithRef, useEffect } from 'react';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckIcon from '@mui/icons-material/Check';

import {
  COLOURS,
  Languages,
  ResourceLocationProperties,
  ApprovalStatus,
  servicesOffered,
} from 'carefinder-shared-utilities';
import { useCurrentLanguage } from 'hive-react-utils';

export interface DetailPanelProperties {
  location: any;
  onUpdateSubmit: (
    locId: string,
    loc: ResourceLocationProperties
  ) => Promise<any>;
}

export default function DetailPanel({
  location,
  onUpdateSubmit,
}: PropsWithRef<DetailPanelProperties>): JSX.Element {
  const props = location?.properties;
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const [status, setStatus] = useState<string>(props.approvalStatus);
  const [rejectReason, setRejectReason] = useState<string | undefined>(
    props.rejectReason
  );

  useEffect(() => {
    setStatus(location?.properties.approvalStatus);
    setRejectReason(location?.properties.rejectReason);
  }, [location]);

  const handleSave = useCallback(() => {
    if (!rejectReason && status === ApprovalStatus.Rejected) {
      return;
    }

    const formattedLocation: ResourceLocationProperties = {
      ...props,
      approvalStatus: status,
      rejectReason: status === ApprovalStatus.Rejected ? rejectReason : '',
    };

    onUpdateSubmit(location.id, formattedLocation);
  }, [location, onUpdateSubmit, props, status, rejectReason]);

  return (
    <Stack width="100%" spacing={3} mx={4} mt={4} marginBottom={4}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.address')}
          </Typography>
          <Typography>{props.address}</Typography>
        </Stack>

        <Stack flexDirection="row" width="50%">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.phone_number')}
          </Typography>
          <a href="tel:PHONE_NUM">{props.phoneNumber}</a>
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.services')}
          </Typography>
          {_.map(props.servicesOffered, (service) => (
            <Typography key={service} marginRight={2}>
              {`${_.get(servicesOffered, [language, service])},`}
            </Typography>
          ))}
        </Stack>

        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.business_hours')}
          </Typography>
          <Typography>{_.join(props.businessHours, ', ')}</Typography>
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.virtual')}
          </Typography>
          {props.isVirtual ? <CheckIcon /> : <DoNotDisturbIcon />}
        </Stack>
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.in_home')}
          </Typography>
          {props.inHomeService ? <CheckIcon /> : <DoNotDisturbIcon />}
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.fees')}
          </Typography>
          <Typography>{props.fees}</Typography>
        </Stack>

        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.referral')}
          </Typography>
          <Typography>{props.referralRequired}</Typography>
        </Stack>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.website')}
          </Typography>
          <Link
            className="details-panel-link"
            target="_blank"
            href={props.websiteUrl}
            fontWeight="bold"
          >
            {props.websiteUrl}
          </Link>
        </Stack>

        <Stack flexDirection="row" width="50%" flexWrap="wrap">
          <Typography fontWeight="bold" marginRight={2}>
            {t('content.locations.map_link')}
          </Typography>
          <Link
            className="details-panel-link"
            target="_blank"
            href={props.googleMapLink}
            fontWeight="bold"
          >
            {props.googleMapLink}
          </Link>
        </Stack>
      </Stack>

      {_.map(
        _.filter(Languages, (lang) => lang !== language),
        (l) => (
          <Stack key={l}>
            <Typography
              variant="h6"
              color={COLOURS.Primary}
              fontWeight="bold"
              marginTop={5}
            >
              {l.toUpperCase()}
            </Typography>

            <Stack flexDirection="row" justifyContent="space-between">
              <Stack flexDirection="row" width="50%" flexWrap="wrap">
                <Typography fontWeight="bold" marginRight={2}>
                  {t('content.locations.name')}
                </Typography>
                <Typography>{props.languages?.[l]?.name}</Typography>
              </Stack>

              <Stack flexDirection="row" width="50%" flexWrap="wrap">
                <Typography fontWeight="bold" marginRight={2}>
                  {t('content.locations.business_hours')}
                </Typography>
                <Typography>
                  {_.join(props.languages?.[l]?.businessHours, ',')}
                </Typography>
              </Stack>
            </Stack>

            <Stack flexDirection="row" justifyContent="space-between">
              <Stack flexDirection="row" width="50%" flexWrap="wrap">
                <Typography fontWeight="bold" marginRight={2}>
                  {t('content.locations.fees')}
                </Typography>
                <Typography>{props.languages?.[l]?.fees}</Typography>
              </Stack>

              <Stack flexDirection="row" width="50%" flexWrap="wrap">
                <Typography fontWeight="bold" marginRight={2}>
                  {t('content.locations.referral')}
                </Typography>
                <Typography>
                  {props.languages?.[l]?.referralRequired}
                </Typography>
              </Stack>
            </Stack>

            <Stack width="50%" border="1px solid" marginTop={2} padding={3}>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                paddingBottom={2}
              >
                <Typography fontWeight="bold" marginRight={2}>
                  {t('content.locations.status')}
                </Typography>
                <Select
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  {_.map(ApprovalStatus, (v: number) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="contained"
                  fullWidth={false}
                  onClick={handleSave}
                >
                  {t('common.save')}
                </Button>
              </Stack>
              {status === 'Rejected' && (
                <TextField
                  multiline
                  error={!rejectReason}
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  label={t('content.locations.reason')}
                  helperText={t('content.locations.required')}
                  inputProps={{ maxLength: 256 }}
                />
              )}
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
}
