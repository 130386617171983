import { useRef, useMemo, useCallback } from 'react';
import { IconButton, Stack } from '@mui/material';
import { CSVLink } from 'react-csv';
import _fp from 'lodash/fp';
import moment from 'moment';

import { ReactComponent as ExportIcon } from '../../assets/export.svg';
import { SurveyResults } from 'carefinder-shared-utilities';
import { SURVEY_CSV_FILENAME } from '../constants';
import { flattenObject } from '../../utils/utils';

export interface SurveyExportProps {
  surveys: SurveyResults[];
  formatDate: (date?: string | moment.Moment) => string;
}

export default function SurveyExport({
  surveys,
  formatDate,
}: SurveyExportProps): JSX.Element | null {
  const CSVLinkRef = useRef(null);

  const filenameSurveyCSV = useMemo(() => {
    const currentDate = formatDate(moment());
    return SURVEY_CSV_FILENAME.replace('{name}', currentDate);
  }, [formatDate]);

  // TODO: Investigate/Redo formatting for CSV -> +flattenObject
  const formattedData = useMemo(() => {
    return _fp.flow([
      //dummy comment to force multipe lines
      _fp.map('properties.responses'),
      _fp.map(flattenObject),
    ])(surveys);
  }, [surveys]);

  const exportClickHandler = useCallback(() => {
    (CSVLinkRef.current as any).link.click();
  }, [CSVLinkRef]);

  return (
    <Stack direction="row" justifyContent="flex-end" padding={0.5}>
      <CSVLink
        data={formattedData}
        filename={filenameSurveyCSV}
        ref={CSVLinkRef}
        target="_blank"
      />
      <IconButton
        color="primary"
        disabled={surveys.length === 0}
        onClick={exportClickHandler}
      >
        <ExportIcon />
      </IconButton>
    </Stack>
  );
}
