import { Stack } from '@mui/material';
import { useMemo, useState, useContext } from 'react';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { surveyLocalization } from 'survey-core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  useLatestSurvey,
  useApiCallWithMessageBar,
  SurveyService,
} from 'carefinder-shared-utilities';

import { DataContext } from '../../contexts/DataContext';

const CREATOR_OPTIONS = {
  showLogicTab: true,
  showTranslationTab: true,
};

const SURVEY_VERSION = 1;

export default function SurveyBuilderPage(): JSX.Element {
  surveyLocalization.supportedLocales = ['en', 'fr', 'de', 'es', 'ko', 'uk'];

  const [surveyID, setSurveyID] = useState<string | null>(null);
  const { t } = useTranslation();
  const { hasAdministratorRole } = useContext(DataContext);
  const latestSurvey = useLatestSurvey();

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!hasAdministratorRole,
    execute: (entry: object) =>
      surveyID
        ? SurveyService.updateSurvey(surveyID, entry!, SURVEY_VERSION)
        : SurveyService.addSurvey(entry!, SURVEY_VERSION),
    success: {
      message: t('common.success_submit'),
    },
    failure: {
      message: t('common.failed_submit'),
    },
  });

  const surveyJSON = useMemo(() => {
    const DEFAULT_JSON = {
      pages: [
        {
          name: 'PG1',
          elements: [
            {
              name: 'FirstName',
              title: t('content.survey.builder.first_name'),
              type: 'text',
            },
            {
              name: 'LastName',
              title: t('content.survey.builder.last_name'),
              type: 'text',
            },
          ],
        },
      ],
    };

    if (_.isEmpty(latestSurvey)) {
      return JSON.stringify(DEFAULT_JSON);
    }
    setSurveyID(latestSurvey.id);
    return JSON.stringify(latestSurvey.properties.body);
  }, [latestSurvey, t]);

  const creator = useMemo(() => {
    const newCreator = new SurveyCreator(CREATOR_OPTIONS);

    newCreator.saveSurveyFunc = () => {
      const stringifiedText = JSON.parse(newCreator.text);
      onSubmit(stringifiedText);
    };
    newCreator.text = surveyJSON;
    return newCreator;
  }, [onSubmit, surveyJSON]);

  return (
    <Stack direction="row" spacing={0}>
      <SurveyCreatorComponent creator={creator} />
    </Stack>
  );
}
