import { useCallback, useMemo, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Container,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useConnection,
  useConnectionState,
  useLoginCallback,
} from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import _ from 'lodash';
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router-dom';
import {
  AnalyticsService,
  LoggedConnectionService,
} from 'hive-analytics-react';

import CarefinderLogo from '../assets/CarefinderLogo.png';
import {
  PATH_METRICS,
  STORAGE_CURRENT_TAB,
  PATH_SURVEY_BUILDER,
  PATH_SURVEYS,
  PATH_LOCATION_REVIEW,
} from '../content/constants';
import LanguageToggle from './LanguageToggle';
import { InviteAdministratorDialog } from './InviteAdministratorDialog';
import { DataContext } from '../contexts/DataContext';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

function HeaderMenu({
  anchorEl,
  onClose,
  onInvite,
}: {
  anchorEl: Element | null;
  onClose: () => void;
  onInvite: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const connectionState = useConnectionState();

  const handleLogin = useLoginCallback(OIDC_PATH, onClose);

  const handleLogout = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGOUT');
    LoggedConnectionService.releaseBee('HEADER_MENU');
    onClose();
  }, [onClose]);

  const items = useMemo(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return [
          <MenuItem key="sign-in" onClick={handleLogin}>
            <>{t('header.login')}</>
          </MenuItem>,
        ];

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return [
          <MenuItem key="invite-administrator" onClick={onInvite}>
            {t('content.invite_administrator.title')}
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleLogout}>
            <>{t('header.logout')}</>
          </MenuItem>,
        ];
    }

    return [];
  }, [connectionState, t, handleLogin, handleLogout, onInvite]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
}

function MenuAndLanguage(): JSX.Element {
  const connection = useConnection();
  const username = _.get(connection, 'username', '');

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [showInviteAdministratorDialog, setShowInviteAdministratorDialog] =
    useState(false);

  const handleOpenMenu = (event: any) => {
    AnalyticsService.logClick('HEADER_MENU_SHOWN');
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <HeaderMenu
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
        onInvite={() => setShowInviteAdministratorDialog(true)}
      />

      <Stack direction="column" spacing={0}>
        <Box alignSelf="end">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleOpenMenu}
          >
            <Stack direction="row" spacing={username ? 1 : 0}>
              <Typography>{username}</Typography>
              <MenuIcon />
            </Stack>
          </IconButton>
        </Box>

        <LanguageToggle />

        <InviteAdministratorDialog
          open={showInviteAdministratorDialog}
          onClose={() => setShowInviteAdministratorDialog(false)}
        />
      </Stack>
    </>
  );
}

const PATHS_TO_TAB_VALUE = [
  [PATH_METRICS, PATH_METRICS],
  [PATH_SURVEY_BUILDER, PATH_SURVEY_BUILDER],
  [PATH_SURVEYS, PATH_SURVEYS],
  [PATH_LOCATION_REVIEW, PATH_LOCATION_REVIEW],
];

function HeaderTabs(): JSX.Element | null {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Determine which tab based on the location
  const location = useLocation();
  const currentTab = useMemo(() => {
    for (const [pattern, tabValue] of PATHS_TO_TAB_VALUE) {
      if (matchPath(pattern, location.pathname)) {
        return tabValue;
      }
    }

    // Patients list by default
    return PATH_METRICS;
  }, [location]);

  const onTabChange = useCallback(
    (_event: any, value: string) => {
      navigate(value);
      localStorage.setItem(STORAGE_CURRENT_TAB, value);
    },
    [navigate]
  );

  return (
    <Stack className="header-tabs" flexGrow="1">
      <Tabs value={currentTab} onChange={onTabChange}>
        <Tab value={PATH_METRICS} label={t('header.tabs.events')} />
        <Tab value={PATH_SURVEYS} label={t('header.tabs.surveys')} />
        <Tab
          value={PATH_SURVEY_BUILDER}
          label={t('header.tabs.survey_builder')}
        />
        <Tab
          value={PATH_LOCATION_REVIEW}
          label={t('header.tabs.locations_review')}
        />
      </Tabs>
    </Stack>
  );
}

export default function Header(): JSX.Element {
  const { hasAdministratorRole } = useContext(DataContext);

  return (
    <header>
      <Paper>
        <AppBar position="static">
          <Toolbar>
            <RouterLink to="/">
              <img src={CarefinderLogo} alt="Logo" />
            </RouterLink>

            <Container />

            {hasAdministratorRole && <HeaderTabs />}

            <MenuAndLanguage />
          </Toolbar>
        </AppBar>
      </Paper>
    </header>
  );
}
