import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { PropsWithRef, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

const LABEL_WIDTH = 40;

export interface InfoItemProps {
  disabled?: boolean;
  label: string;
  value?: string;
  labelWidth?: number;
  link?: string;
  navLink?: string;
  formatter?: (value: string) => string;
}

export function SurveyInfoItem({
  label,
  value = '',
  labelWidth,
  link,
  navLink,
  formatter = _.identity,
}: PropsWithRef<InfoItemProps>) {
  const component = useMemo(() => {
    if (link) {
      return <MuiLink href={link}>{formatter(value)}</MuiLink>;
    }

    if (navLink) {
      return <RouterLink to={navLink}>{formatter(value)}</RouterLink>;
    }

    return value;
  }, [link, navLink, value, formatter]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="body1" width={`${labelWidth || LABEL_WIDTH}%`}>
        {label}
      </Typography>

      <Typography
        variant="body2"
        width={`${100 - (labelWidth || LABEL_WIDTH)}%`}
        paddingRight={2}
      >
        {component}
      </Typography>
    </Stack>
  );
}
