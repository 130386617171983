import { Link as MuiLink, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'hive-react-utils';

import { ReactComponent as LogoEn } from '../assets/mac-HealthDefinesUs-en.svg';
import { ReactComponent as LogoFr } from '../assets/mac-HealthDefinesUs-fr.svg';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const logo = language === 'fr' ? <LogoFr /> : <LogoEn />;
  const contactEmail = t('footer.contactEmail');

  return (
    <footer>
      <Paper>
        <Stack
          direction="row"
          spacing={1}
          padding={1}
          paddingLeft={2}
          paddingRight={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <MuiLink
            href="https://www.macadamian.com"
            target="_blank"
            display="contents"
          >
            {logo}
          </MuiLink>

          <MuiLink variant="body2" href={contactEmail}>
            <>{t('footer.contactUs')}</>
          </MuiLink>
        </Stack>
      </Paper>
    </footer>
  );
}
