import { Navigate, Route, Routes } from 'react-router-dom';

import {
  PATH_METRICS,
  PATH_SURVEYS,
  PATH_SURVEY_BUILDER,
  PATH_LOCATION_REVIEW,
} from './constants';
import LocationReviews from './locationReviews/LocationReviews';
import { Metrics } from './metrics/Metrics';
import SurveyBuilder from './surveys/SurveyBuilder';
import Surveys from './surveys/Surveys';

export default function Content(): JSX.Element {
  return (
    <Routes>
      <Route path={PATH_METRICS} element={<Metrics />} />
      <Route path={PATH_SURVEYS} element={<Surveys />} />
      <Route path={PATH_SURVEY_BUILDER} element={<SurveyBuilder />} />
      <Route path={PATH_LOCATION_REVIEW} element={<LocationReviews />} />
      <Route path="*" element={<Navigate to={PATH_METRICS} />} />
    </Routes>
  );
}
