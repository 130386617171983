import React from 'react';
import ReactDOM from 'react-dom';
import { AppNames, LoginService } from 'carefinder-shared-utilities';
import './index.css';
import App from './App';

// The following just need to be imported to start things
import './services/LocalizationService';

const KEY_TOKEN = 'hive-token';
const KEY_TOKEN_EXPIRATION = 'hive-token-expiration';
const KEY_USERNAME = 'hive-username';

LoginService.init({
  appName: AppNames.User,
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const container = document.getElementById('root') as Element;
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    container
  );
});
