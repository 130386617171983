import { IconButton, Stack } from '@mui/material';
import React, { PropsWithRef, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Event, useLoggedConnection } from 'hive-analytics-react';

import {
  formatAppName,
  formatDateAndTime,
  formatDuration,
  formatShortUUid,
  filterEvents,
} from '../content/metrics/utilities';
import { ReactComponent as ExportIcon } from '../assets/export.svg';
import { FilterContext } from '../content/metrics/FilterContext';

export interface ExportProperties {
  sessions: any[];
}

export function MetricsExport({
  sessions,
}: PropsWithRef<ExportProperties>): JSX.Element {
  const [exportedCsvData, setExportedCsvData] = useState<{}[]>([]);
  const [readyToExport, setReadyToExport] = useState(0);
  const csvlinkref = React.createRef<any>();
  const { t } = useTranslation();
  const connection = useLoggedConnection();

  useEffect(() => {
    if (exportedCsvData.length > 0) {
      // This will give the change to the re-render to happen to update the data on CSVLInk component,
      // then we could simulate the click on CSVLink
      setReadyToExport(Math.random());
    }
  }, [exportedCsvData]);

  // This effect will be trigger when the data is ready to be exported
  useEffect(
    () => {
      if (exportedCsvData.length > 0) {
        (csvlinkref?.current as any).link.click();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyToExport]
  );

  const filter = useContext(FilterContext);

  const handleExport = async () => {
    let csv: {}[] = [];

    for (let session of sessions) {
      let csvData = {};
      const { startTime, endTime } = filter;
      const rawEvents: Event[] | undefined =
        (await connection?.bee.storage.executeQuery(
          'analytics',
          'eventsByDateAndSessionId',
          [startTime, endTime, session.id]
        )) as any;

      const events = filterEvents(rawEvents || [], filter);

      _.each(events, (event) => {
        csvData = {
          ID: formatShortUUid(session?.id),
          UserID: formatShortUUid(session?.userId),
          'Start Time': formatDateAndTime(session.startTime),
          Duration: formatDuration(session?.duration),
          Browser: session?.browser,
          Event: event.properties.eventId,
          'User Agent': event.properties.userAgent,
          Platform: session?.platform,
          Environment: event.properties.environment,
          'App Name': formatAppName(t, session),
          'App Version': session?.appVersion,
        };

        csv.push(csvData);
      });
    }

    setExportedCsvData(csv);
  };

  return (
    <Stack>
      <CSVLink
        style={{ display: 'none' }}
        data={exportedCsvData}
        filename="Carefinder-Analytics.csv"
        ref={csvlinkref}
        target="_blank"
      ></CSVLink>
      <IconButton
        color="primary"
        onClick={handleExport}
        style={{ padding: '2px' }}
      >
        <ExportIcon />
      </IconButton>
    </Stack>
  );
}
