import { PrivacyPolicy_en as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_en as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Care Finder';

export const en = {
  app: {
    title: APP_TITLE,
  },
  common: {
    next: 'Next',
    cancel: 'Cancel',
    back: 'Back',
    save: 'Save',
    success_submit: 'Successfully Submitted',
    failed_submit: 'Submission Failed',
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    logout: 'Sign Out',
    tabs: {
      events: 'Events',
      surveys: 'Surveys',
      survey_builder: 'Survey Builder',
      locations_review: 'Location Review',
    },
  },
  footer: {
    contactUs: 'Need help? Contact us.',
    contactEmail: 'mailto:support@macadamian.com',
    privacyPolicyTitle: 'Privacy Policy',
    privacyPolicy: 'privacy policy',
    privacyPolicyText,
    termsOfServiceTitle: 'Terms of Service',
    termsOfService: 'terms of service',
    termsOfServiceText,
  },
  signin: {
    please_sign_in: 'Please sign in...',
  },
  content: {
    metrics: {
      title: 'Session History',
      search: {
        placeholder: 'Search',
      },
      session: {
        summary: {
          session_id: 'Session ID',
          user_id: 'User ID',
          start: 'Session Start',
          duration: 'Session Duration',
          app: 'Application',
          version: 'App Version',
          platform: 'Platform',
          browser: 'Browser',
        },
      },
      events: {
        columns: {
          time: 'Time',
          event: 'Event',
          elapsed: 'Elapsed Time (ms)',
          info: 'Info',
        },
      },
      filters: {
        period_range: {
          title: 'Period Range',
          start: 'Start Date / Time',
          end: 'End Date / Time',
          last_24_hours: 'Last 24 hours',
          last_7_days: 'Last 7 days',
          last_30_days: 'Last 30 days',
          date_range: 'Date Range',
        },
        applications: {
          title: 'Application',
          names: {
            short: {
              admin: 'Admin',
              user: 'User',
              unknown: 'Unknown',
            },
            long: {
              admin: 'Admin Application',
              user: 'User Application',
              unknown: 'Unknown Application',
            },
          },
        },
        users: {
          title: 'Users',
          all: 'All',
        },
        sessions: {
          title: 'Sessions',
          all: 'All',
        },
        events: {
          title: 'Events',
          all: 'All',
        },
      },
    },
    invite_administrator: {
      title: 'Invite Administrator',
      send: 'Send Invitation',
      name: 'Name',
      email: 'Email',
    },
    survey: {
      info: {
        title: 'Survey Info',
        labels: {
          name: 'Name',
          comments: 'Comments',
          email: 'Email',
          score: 'Score',
          phone: 'Phone',
          version: 'Version',
          survey_date: 'Survey Date',
        },
        n_a: 'N/A',
      },
      navigation: {
        previous_survey: 'Previous Survey',
        next_survey: 'Next Survey',
        all_surveys: 'All Surveys List',
      },
      builder: {
        first_name: 'Please enter your first name:',
        last_name: 'Please enter your last name:',
      },
    },
    survey_table: {
      title: 'Surveys ({{count}})',
      search_placeholder: 'Search',
      survey_name: 'Name',
      survey_score: 'Score',
      survey_comments: 'Comments',
      version: 'Version',
      survey_date: 'Date Created',
    },
    location_reviews: {
      filter: 'Filter By:',
      title: 'Location Reviews',
      name: 'Service Location Name',
      phone_number: 'Phone Number',
      address: 'Address',
      approval_status: 'Approval Status',
      date: 'Date Created',
      provider: 'Provider Name',
      services_offered: 'Services Provided',
      approved: 'Approved',
      rejected: 'Rejected',
      pending: 'Pending',
      edited: 'Edited',
      location_count: 'Location(s)',
      description: 'Description',
      year_since: 'Year Since',
      approve_all: 'Approve All',
      provider_title: 'Title',
      email: 'Email',
    },
    locations: {
      name: 'Service Location Name:',
      provider_name: 'Provider Name:',
      services: 'Service(s) offered at this location:',
      phone_number: 'Phone Number:',
      address: 'Address:',
      business_hours: 'Business Hours:',
      fees: 'Fees:',
      referral: 'Is a referral required:',
      map_link: 'Service Google Map Link:',
      website: 'Website URL:',
      status: 'Approval Status:',
      virtual: 'Offers virtual services:',
      in_home: 'Offers in home services:',
      provider_description: 'Provider description:',
      reason: 'Reason for Rejection',
      required: 'This is a required field',
    },
  },
  not_implemented: 'State "{{connectionState}}" not implemented"',
};
