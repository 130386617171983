import { Button, Stack } from '@mui/material';
import { useLoginCallback } from 'hive-react-utils';
import { useTranslation } from 'react-i18next';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

export default function Disconnected(): JSX.Element {
  const { t } = useTranslation();

  const handleLogin = useLoginCallback(OIDC_PATH);

  return (
    <Stack flexGrow="1" justifyContent="center" alignItems="center">
      <Button variant="contained" onClick={handleLogin}>
        <>{t('signin.please_sign_in')}</>
      </Button>
    </Stack>
  );
}
