import { Fragment, useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import _ from 'lodash';
import {
  useCurrentLanguage,
  useLanguages,
  changeCurrentLanguage,
} from 'hive-react-utils';

function LanguageButton({
  language,
  current,
}: {
  language: string;
  current: string;
}): JSX.Element {
  const changeLanguage = useCallback(
    () => changeCurrentLanguage(language),
    [language]
  );

  const variant = language === current ? 'selectableSelected' : 'selectable';
  return (
    <Button
      // The common theme in the shared library augments the mjui module, but can't export it properly.
      // The selectable styles do exist, but let's not fight it too much. any is allowed here.
      variant={variant as any}
      size="small"
      color="inherit"
      aria-label={`language-button-${language}`}
      onClick={changeLanguage}
    >
      {language}
    </Button>
  );
}

export default function LanguageToggle(): JSX.Element {
  const current = useCurrentLanguage();
  const languages = useLanguages();
  return (
    <Stack direction="row" spacing={2} justifyContent="end">
      {_.map(languages, (l) => (
        <Fragment key={l}>
          <LanguageButton language={l} current={current} />
        </Fragment>
      ))}
    </Stack>
  );
}
