import { IconButton, Stack } from '@mui/material';
import React, {
  PropsWithRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import { ReactComponent as ExportIcon } from '../assets/export.svg';
import { Provider, ResourceLocation } from 'carefinder-shared-utilities';

export interface ExportProperties {
  locations?: Provider[];
}

export function LocationsExport({
  locations,
}: PropsWithRef<ExportProperties>): JSX.Element | null {
  const [exportedCsvData, setExportedCsvData] = useState<{}[]>([]);
  const [readyToExport, setReadyToExport] = useState(0);
  const csvlinkref = React.createRef<any>();

  const locationsWithProvider = useMemo(() => {
    // Add the Provider's information to each location
    return _.flatMap(locations, (provider: Provider) =>
      _.map(provider.properties.locations, (loc: ResourceLocation) => ({
        ...loc,
        properties: {
          ...loc.properties,
          // Add the Provider's information to each location's properties
          firstName: provider.properties.firstName,
          lastName: provider.properties.lastName,
          email: provider.properties.email,
          description: provider.properties.quote,
          yearStartedInField: provider.properties.yearStartedInField,
        },
      }))
    );
  }, [locations]);

  useEffect(() => {
    if (exportedCsvData.length > 0) {
      // This will give the change to the re-render to happen to update the data on CSVLInk component,
      // then we could simulate the click on CSVLink
      setReadyToExport(Math.random());
    }
  }, [exportedCsvData]);

  // This effect will be trigger when the data is ready to be exported
  useEffect(
    () => {
      if (exportedCsvData.length > 0) {
        (csvlinkref?.current as any).link.click();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyToExport]
  );

  const handleExport = useCallback(async () => {
    const csv = _.map(locationsWithProvider, (l) => ({
      'First Name': l.properties.firstName,
      'Last Name': l.properties.lastName,
      Email: l.properties.email,
      yearStarted: l.properties.yearStartedInField,
      LocationID: l.properties.locationID,
      Description: l.properties.description,
      Name: l.properties.name,
      Address: l.properties.address,
      Coordinates: _.replace(JSON.stringify(l.properties.coords), /,/g, ';'),
      'Phone Number': l.properties.phoneNumber,
      'Services Offered': l.properties.servicesOffered.toString(),
      'Website Url': l.properties.websiteUrl,
      'Google Map Link': l.properties.googleMapLink,
      'Referral Required': l.properties.referralRequired,
      Fees: l.properties.fees,
      'Business Hours': l.properties.businessHours?.toString(),
      'Provider ID': l.properties.providerID,
      'Approval Status': l.properties.approvalStatus,
      Deleted: l.properties.deleted,
      'Is Virtual': l.properties.isVirtual,
      'In Home': l.properties.inHomeService,
      languages: _.replace(JSON.stringify(l.properties.languages), /,/g, ';'),
    }));

    setExportedCsvData(csv);
  }, [locationsWithProvider, setExportedCsvData]);

  if (!locations) {
    return null;
  }

  return (
    <Stack>
      <CSVLink
        style={{ display: 'none' }}
        data={exportedCsvData}
        filename="Carefinder-Locations.csv"
        ref={csvlinkref}
        target="_blank"
      ></CSVLink>
      <IconButton
        color="primary"
        onClick={handleExport}
        style={{ padding: '2px' }}
      >
        <ExportIcon />
      </IconButton>
    </Stack>
  );
}
