import { useContext, useMemo } from 'react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { ApprovalStatus } from '../types';
import { CommonDataContext } from '../contexts';
// for all providers, return an object where the property in `provider` includes a location, which has all the locations with that provider ID
export function useProviderWithResourceLocations() {
    const { providers: allProviders, resourceLocations: allLocations } = useContext(CommonDataContext);
    return useMemo(() => {
        if (allProviders === undefined || allLocations === undefined) {
            return;
        }
        const providersWithLocations = _fp.flow([
            _fp.map((provider) => {
                // additional properties to return to LocationsReview component table
                let locationCount = 0;
                const locationsStatuses = {
                    [ApprovalStatus.Approved]: 0,
                    [ApprovalStatus.Pending]: 0,
                    [ApprovalStatus.Rejected]: 0,
                    [ApprovalStatus.Edited]: 0,
                };
                let uniqueServicesOffered = '';
                return Object.assign(Object.assign({}, provider), { properties: Object.assign(Object.assign({}, provider.properties), { locations: 
                        // break line for formatting
                        _fp.flow([
                            // filter locations to match current provider's ID
                            _fp.filter((location) => location.properties.providerID ===
                                provider.properties.providerID),
                            _fp.tap((locations) => {
                                locationCount = _.size(locations);
                            }),
                            // aggregate the status counts for all filtered locations
                            _fp.tap((locations) => {
                                const aggregatedLocStatuses = _fp.flow([
                                    _fp.map((location) => ({
                                        status: location.properties.approvalStatus,
                                    })),
                                    _fp.countBy('status'),
                                ])(locations);
                                locationsStatuses[ApprovalStatus.Pending] =
                                    (aggregatedLocStatuses === null || aggregatedLocStatuses === void 0 ? void 0 : aggregatedLocStatuses[ApprovalStatus.Pending]) || 0;
                                locationsStatuses[ApprovalStatus.Approved] =
                                    (aggregatedLocStatuses === null || aggregatedLocStatuses === void 0 ? void 0 : aggregatedLocStatuses[ApprovalStatus.Approved]) || 0;
                                locationsStatuses[ApprovalStatus.Rejected] =
                                    (aggregatedLocStatuses === null || aggregatedLocStatuses === void 0 ? void 0 : aggregatedLocStatuses[ApprovalStatus.Rejected]) || 0;
                                locationsStatuses[ApprovalStatus.Edited] =
                                    (aggregatedLocStatuses === null || aggregatedLocStatuses === void 0 ? void 0 : aggregatedLocStatuses[ApprovalStatus.Edited]) || 0;
                            }),
                            // aggregate all the unique services for all filtered locations
                            // Note: possibly no services if provider has no locations
                            _fp.tap((locations) => {
                                const uniqServicesOffered = _fp.flow([
                                    _fp.map('properties.servicesOffered'),
                                    _fp.flatten,
                                    _fp.uniq,
                                    _fp.map(_fp.capitalize),
                                    _fp.join(', '),
                                ])(locations);
                                uniqueServicesOffered = uniqServicesOffered;
                            }),
                        ])(allLocations), locationCount: locationCount, locationsStatuses,
                        uniqueServicesOffered }) });
            }),
        ])(allProviders);
        return providersWithLocations;
    }, [allProviders, allLocations]);
}
