import { PrivacyPolicy_fr as privacyPolicyText } from './PrivacyPolicy';
import { TermsOfService_fr as termsOfServiceText } from './TermsOfService';

const APP_TITLE = 'Recherche de soins';

export const fr = {
  app: {
    title: APP_TITLE,
  },
  common: {
    next: 'Suivant',
    cancel: 'Annuler',
    back: 'Retour',
    save: 'Savegardez',
    success_submit: 'Soumis avec succès',
    failed_submit: 'Echec de la soumission',
  },
  header: {
    title: APP_TITLE,
    login: 'Connexion',
    logout: 'Déconnexion',
    tabs: {
      events: 'Événements',
      surveys: 'Sondages',
      survey_builder: 'Créateur de sondages',
      locations_review: "Révision de l'emplacement",
    },
  },
  footer: {
    contactUs: "Besoin d'aide? Contactez-nous.",
    contactEmail: 'mailto:support@macadamian.com',
    privacyPolicyTitle: 'Politique de confidentialité',
    privacyPolicy: 'Politique de confidentialité',
    privacyPolicyText,
    termsOfServiceTitle: 'Modalités de service',
    termsOfService: 'Modalités de service',
    termsOfServiceText,
  },
  signin: {
    please_sign_in: 'Veuillez vous connecter...',
  },
  content: {
    metrics: {
      title: 'Historique des sessions',
      search: {
        placeholder: 'Recherche',
      },
      session: {
        summary: {
          session_id: 'Identifiant de session',
          user_id: "Identifiant de l'utilisateur",
          start: 'Début de la session',
          duration: 'Durée de la session',
          app: 'Application',
          version: "Version d'application",
          platform: 'Plateforme',
          browser: 'Navigateur',
        },
      },
      events: {
        columns: {
          time: 'Date/Heure',
          event: 'Événement',
          elapsed: 'Temps écoulé (ms)',
          info: 'Info',
        },
      },
      filters: {
        period_range: {
          title: 'Plage de périodes',
          start: 'Date et heure de début',
          end: 'Date et heure de début fin',
          last_24_hours: 'Dernières 24 heures',
          last_7_days: 'Derniers 7 jours',
          last_30_days: 'Derniers 30 jours',
          date_range: 'Plage de dates',
        },
        applications: {
          title: 'Application',
          names: {
            short: {
              admin: 'Admin',
              user: 'Utilisateur',
              unknown: 'Incon',
            },
            long: {
              admin: 'Application admin',
              user: 'Application utilisateur',
              unknown: 'Application inconnue',
            },
          },
        },
        users: {
          title: 'Utilisateurs',
          all: 'Tous',
        },
        sessions: {
          title: 'Sessions',
          all: 'Tous',
        },
        events: {
          title: 'Événements',
          all: 'Tous',
        },
      },
    },
    invite_administrator: {
      title: "Inviter l'administrateur",
      send: "Envoyer l'invitation",
      name: 'Nom',
      email: 'Courriel',
    },
    survey: {
      info: {
        title: "Info sur l'enquête",
        labels: {
          name: 'Nom',
          comments: 'Commentaires',
          email: 'Courriel',
          score: 'Pointage',
          phone: 'Téléphone',
          version: 'Version',
          survey_date: "Date de l'enquête",
        },
        n_a: 'N/A',
      },
      navigation: {
        previous_survey: 'Enquête précédente',
        next_survey: 'Enquête suivante',
        all_surveys: 'Liste de toutes les enquêtes',
      },
      builder: {
        first_name: 'Veuillez entrer votre prénom:',
        last_name: 'Veuillez entrer votre nom de famille:',
      },
    },
    survey_table: {
      title: 'Enquêtes ({{count}})',
      search_placeholder: 'Recherche',
      survey_name: 'Nom',
      survey_score: 'Score',
      survey_comments: 'Commentaires',
      version: 'Version',
      survey_date: 'Date de création',
    },
    location_reviews: {
      filter: 'Filtrer par:',
      title: "Avis sur l'emplacement",
      name: "Nom de l'emplacement du service",
      phone_number: 'Numéro de téléphone',
      address: 'Adresse',
      approval_status: "Avis sur l'emplacement",
      date: 'Date de création',
      provider: 'Nom du fournisseur',
      services_offered: 'Services fournis',
      approved: 'Approuvé',
      rejected: 'Rejeté',
      pending: 'En attente',
      edited: 'Édité',
      location_count: 'Localisation(s)',
      description: 'Description',
      year_since: 'Année Depuis',
      approve_all: 'Approuver tous les',
      provider_title: 'Titre',
      email: 'Courriel',
    },
    locations: {
      name: "Nom de l'emplacement du service:",
      provider_name: 'Nom du fournisseur:',
      services: 'Service(s) offert(s) à cet endroit:',
      phone_number: 'Numéro de téléphone:',
      address: 'Adresse:',
      business_hours: "Heures d'ouverture:",
      fees: 'Frais:',
      referral: 'Une recommandation est-elle nécessaire:',
      map_link: 'Lien vers la carte Google du service:',
      website: 'URL du site Web:',
      status: "Statut d'approbation:",
      virtual: 'Offre des services virtuels:',
      in_home: 'Offre des services à domicile:',
      provider_description: 'Description du fournisseur:',
      reason: 'Motif du rejet',
      required: 'Ce champ est obligatoire',
    },
  },
  not_implemented: 'État "{{connectionState}}" non-implanté',
};
