import { createContext, useContext, useMemo } from 'react';
import { useHasRole } from 'hive-react-utils';

export interface DataContextProps {
  hasAdministratorRole?: boolean;
  hasAnalyticsReaderRole?: boolean;
}

export const DataContext = createContext<DataContextProps>({});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.

  const hasAdministratorRole = useHasRole('administrator');
  const hasAnalyticsReaderRole = useHasRole('analyticsReader');

  const value = useMemo(
    () => ({
      hasAdministratorRole,
      hasAnalyticsReaderRole,
    }),
    [hasAdministratorRole, hasAnalyticsReaderRole]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
