import { Stack } from '@mui/material';
import { SurveyResults, spacing } from 'carefinder-shared-utilities';
import { PropsWithRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { SurveyInfoItem } from './SurveyInfoItem';
import { formatDate } from '../../utils/utils';

export interface SurveyInfoProps {
  survey?: SurveyResults;
  width?: number | string;
  flexGrow?: number | string;
}

export function SurveyInfo({
  survey,
  width,
  flexGrow,
}: PropsWithRef<SurveyInfoProps>): JSX.Element {
  const { t } = useTranslation();
  const notAvailable = t('content.survey.info.n_a');
  const surveyDate = useMemo(() => {
    return formatDate(survey?.created);
  }, [survey?.created]);

  return (
    <Stack
      width={width}
      flexGrow={flexGrow}
      className="patient-info"
      padding={3}
      spacing={1}
      marginLeft={10}
    >
      <Stack
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        // We CANNOT easily theme MuiStack, so this sx is permitted here
        sx={{ gridGap: `${spacing * 2}px` }}
      >
        <SurveyInfoItem
          label={t('content.survey.info.labels.version')}
          value={survey?.version.toString()}
        />

        <SurveyInfoItem
          label={t('content.survey.info.labels.survey_date')}
          value={surveyDate || notAvailable}
        />

        {_.map(_.keys(survey?.properties.responses), (key, i) => {
          // render each dynamic question/survey
          const label = t(key.toString());
          const response = survey?.properties.responses[key] || notAvailable;
          return <SurveyInfoItem label={label} value={response} key={i} />;
        })}
      </Stack>
    </Stack>
  );
}
