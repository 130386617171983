import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsService, LoggedDialog } from 'hive-analytics-react';
import {
  DialogTitleWithClose,
  AdministratorService,
  useApiCallWithMessageBar,
  ValidationService,
} from 'carefinder-shared-utilities';

import { DataContext } from '../contexts/DataContext';

export interface InviteAdministratorDialogProperties {
  open: boolean;
  onClose: () => void;
}

export function InviteAdministratorDialog({
  open,
  onClose,
}: InviteAdministratorDialogProperties): JSX.Element {
  const { t } = useTranslation();
  const { hasAdministratorRole } = useContext(DataContext);

  const [inviting, setInviting] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const redirectUri = window.location.origin;

  useEffect(() => {
    setInviting(false);
    setName('');
    setEmail('');
  }, [open]);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!hasAdministratorRole,
    execute: (
      adminName: string,
      adminEmail: string,
      adminRedirectUri: string
    ) =>
      AdministratorService.createAdministrator(
        adminName,
        adminEmail,
        adminRedirectUri
      ),
    success: {
      message: t('common.success_submit'),
    },
    failure: {
      message: t('common.failed_submit'),
    },
  });

  const sendInvitation = useCallback(
    (e: any) => {
      AnalyticsService.logClick('INVITE_ADMINISTRATOR');
      e.preventDefault();
      setInviting(true);

      onSubmit(name, email, redirectUri).then(() => {
        setInviting(false);
        onClose();
      });
    },
    [onClose, onSubmit, name, email, redirectUri]
  );

  const sendDisabled =
    inviting || !name || !ValidationService.validateEmail(email);

  return (
    <LoggedDialog
      dialogName="invite-administrator"
      open={open}
      onClose={inviting ? undefined : onClose}
      fullWidth
    >
      <DialogTitleWithClose onClose={onClose} disabled={inviting}>
        {t('content.invite_administrator.title')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <form onSubmit={sendInvitation}>
          <Stack spacing={6}>
            <TextField
              autoComplete="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              name="name"
              placeholder={t('content.invite_administrator.name')}
              label={t('content.invite_administrator.name')}
              fullWidth
            />

            <TextField
              autoComplete="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              name="email"
              placeholder={t('content.invite_administrator.email')}
              label={t('content.invite_administrator.email')}
              fullWidth
            />
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Button disabled={inviting} onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button disabled={sendDisabled} onClick={sendInvitation}>
          {t('content.invite_administrator.send')}
        </Button>
      </DialogActions>
    </LoggedDialog>
  );
}
