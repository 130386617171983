import { Fragment, useCallback, useContext } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import {
  ApprovalStatus,
  ProviderService,
  ResourceLocation,
  Provider,
  ResourceLocationProperties,
  useApiCallWithMessageBar,
} from 'carefinder-shared-utilities';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import LocationStatusButton from './LocationStatusButton';
import DetailPanel from './DetailPanel';
import { DataContext } from '../../contexts/DataContext';

interface DetailPanelContainerProps {
  locations?: ResourceLocation[];
  provider: Provider;
}

export default function DetailPanelContainer({
  locations,
  provider,
}: DetailPanelContainerProps): JSX.Element {
  const { hasAdministratorRole } = useContext(DataContext);
  const { t } = useTranslation();

  const { callback: onUpdateSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!hasAdministratorRole,
    execute: (
      locationID: string,
      updatedLocation: ResourceLocationProperties
    ) => ProviderService.updateResourceLocation(locationID, updatedLocation),
    success: {
      message: t('common.success_submit'),
    },
    failure: {
      message: t('common.failed_submit'),
    },
  });

  // clicking button will submit all onUpdateSubmit for each ID and pass the new specific formattedLocation data
  const handleClick = useCallback(() => {
    _.forEach(locations, (location: ResourceLocation) => {
      if (location.properties.approvalStatus === ApprovalStatus.Approved) {
        return;
      }

      const formattedLocation: ResourceLocationProperties = {
        ...location.properties,
        approvalStatus: ApprovalStatus.Approved,
      };
      onUpdateSubmit(location.id, formattedLocation);
    });
  }, [locations, onUpdateSubmit]);

  return (
    <>
      <Divider />
      <Stack flexDirection="row" width="100%" margin={4}>
        <Typography fontWeight="bold" marginRight={2}>
          {t('content.locations.provider_description')}
        </Typography>
        <Typography>{provider?.properties?.quote}</Typography>
      </Stack>

      <Divider />

      <Box display="block" width="100%" marginTop={3}>
        {/* only render button if locations is not empty (provider has a location) and not every provider's location is already approved*/}
        {locations &&
          !_.isEmpty(locations) &&
          !_.every(
            locations,
            (location) =>
              location.properties.approvalStatus === ApprovalStatus.Approved
          ) && (
            <Stack direction="row" alignItems="center" spacing={2} margin={4}>
              <LocationStatusButton onClick={handleClick} />
            </Stack>
          )}

        {_.map(locations, (location: ResourceLocation) => {
          return (
            <Fragment key={location.id}>
              <DetailPanel
                location={location}
                onUpdateSubmit={onUpdateSubmit}
              />
              <Divider />
            </Fragment>
          );
        })}
        <Box />
      </Box>
    </>
  );
}
