import { Typography } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { SurveyResults, useSurveyResults } from 'carefinder-shared-utilities';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import _fp from 'lodash/fp';

import { SurveyInfo } from './SurveyInfo';
import { flattenObject, formatDate } from '../../utils/utils';
import SurveyExport from './SurveyExport';

const fpNoCap = (_fp as any).convert({ cap: false });

export default function Surveys() {
  const { t } = useTranslation();
  const surveys = useSurveyResults();

  const renderDateSurvey = useCallback((s: SurveyResults) => {
    return <Typography variant="body2">{formatDate(s.created)}</Typography>;
  }, []);

  const title = t('content.survey_table.title', { count: _.size(surveys) });

  const cleanSurveyResults = useMemo(
    () =>
      _.map(surveys, (survey) => ({
        ...survey,
        properties: {
          ...survey.properties,
          responses: flattenObject(survey.properties.responses),
        },
      })),
    [surveys]
  );

  const uniqueColumns = useMemo(() => {
    // Mandatory columns per #3247
    const baseColumns: Array<Column<SurveyResults>> = [
      {
        title: t('content.survey_table.version'),
        field: 'version',
      },
      {
        title: t('content.survey_table.survey_date'),
        field: 'created',
        defaultSort: 'desc',
        render: renderDateSurvey,
      },
    ];

    const fieldColumns = _fp.flow([
      _fp.flatMap(
        _fp.flow([
          _fp.get('properties.responses'),
          fpNoCap.map((_ignored: any, key: string) => {
            return {
              title: key,
              field: `properties.responses.${key}`,
            };
          }),
        ])
      ),
      _fp.compact,
      _fp.uniqBy('title'),
    ])(cleanSurveyResults);

    return [...fieldColumns, ...baseColumns];
  }, [t, cleanSurveyResults, renderDateSurvey]);

  const options: any = useMemo(
    () => ({
      paging: true,
      searchFieldAlignment: 'right',
      searchFieldVariant: 'outlined',
      pageSize: 15,
      sorting: true,
    }),
    []
  );

  return (
    <div className="surveys-table">
      {surveys && <SurveyExport surveys={surveys} formatDate={formatDate} />}

      <MaterialTable
        title={title}
        options={options}
        columns={uniqueColumns || []}
        data={cleanSurveyResults || []}
        detailPanel={({ rowData }) => <SurveyInfo survey={rowData} />}
      />
    </div>
  );
}
