import moment from 'moment';

export function flattenObject(obj: { [key: string]: any }) {
  let result: { [key: string]: any } = {};

  for (let key in obj) {
    let value = obj[key];
    if (typeof value === 'object') {
      let flatObject = flattenObject(value);
      for (let flatKey in flatObject) {
        result[key + ' ' + flatKey] = flatObject[flatKey];
      }
    } else {
      result[key] = value;
    }
  }

  return result;
}

export function formatDate(
  date?: string | moment.Moment,
  format: string = 'YYYY-MM-DD LT'
): string {
  return moment(date).format(format);
}
