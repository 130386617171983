import { COLOURS } from 'carefinder-shared-utilities';

export const PATH_METRICS = '/metrics';

export const PATH_SURVEYS = '/surveys';
export const PATH_SURVEY_BUILDER = '/survey-builder';
export const PATH_LOCATION_REVIEW = '/locations-review';

export const STORAGE_CURRENT_TAB = 'current-tab';

export const SURVEY_CSV_FILENAME = 'carefinder-survey-results-{name}.csv';

export const LOCATION_ROW_COLOUR_MAP = {
  Rejected: COLOURS.LightRed + '50',
  Pending: COLOURS.LightYellow + '50',
  Approved: COLOURS.LightGreen + '50',
  Edited: COLOURS.LightBlue + '50',
  default: COLOURS.White,
};
