import { PropsWithRef, useMemo } from 'react';

import _ from 'lodash';
import { formatAppNameForFilters } from '../utilities';
import {
  CheckboxGroup,
  CheckboxGroupEntry,
} from '../../../components/CheckboxGroup';
import { AppNames } from 'carefinder-shared-utilities';
import { useTranslation } from 'react-i18next';

export interface ApplicationsProps {
  appNames: string[];

  selectedAppNames: Set<string>;
  setSelectedAppNames: (value: Set<string>) => void;
}

export function Applications({
  appNames,
  selectedAppNames,
  setSelectedAppNames,
}: PropsWithRef<ApplicationsProps>): JSX.Element {
  const { t } = useTranslation();
  const values = useMemo<CheckboxGroupEntry[]>(
    () =>
      _.map(appNames, (name: string) => ({
        id: name,
        label: formatAppNameForFilters(t, name as AppNames),
      })),
    [t, appNames]
  );

  return (
    <CheckboxGroup
      titleId="content.metrics.filters.applications.title"
      values={values}
      selectedValues={selectedAppNames}
      setSelectedValues={setSelectedAppNames}
    />
  );
}
