import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import MaterialTable, { Column, Options } from '@material-table/core';
import {
  ApprovalStatus,
  Provider,
  ProviderWithResourceLocations,
  ResourceLocation,
  useProviderWithResourceLocations,
} from 'carefinder-shared-utilities';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import _fp from 'lodash/fp';

import { LocationsExport } from '../../components/LocationsExport';
import { LOCATION_ROW_COLOUR_MAP } from '../constants';
import DetailPanelContainer from './DetailPanelContainer';
import { formatDate } from '../../utils/utils';

const TABLE_OPTIONS: Options<ProviderWithResourceLocations> = {
  paging: true,
  showTitle: true,
  searchFieldAlignment: 'right',
  searchFieldVariant: 'outlined',
  pageSize: 10,
  sorting: true,
};

export default function LocationReviews() {
  const { t } = useTranslation();
  const providersWithResourceLocations = useProviderWithResourceLocations();
  const [selectedStatuses, setSelectedStatuses] = useState<Set<string>>(
    new Set([])
  );

  const renderDate = useCallback((date: string, format?: string) => {
    return <Typography variant="body2">{formatDate(date, format)}</Typography>;
  }, []);

  const columns = useMemo<Column<any>[]>(() => {
    return [
      {
        title: t('content.location_reviews.provider'),
        field: 'properties.name',
        width: '15%',
      },
      {
        title: t('content.location_reviews.provider_title'),
        field: 'properties.title',
      },
      {
        title: t('content.location_reviews.email'),
        field: 'properties.email',
      },
      {
        title: t('content.location_reviews.services_offered'),
        field: 'properties.uniqueServicesOffered',
      },
      {
        title: t('content.location_reviews.pending'),
        field: 'properties.locationsStatuses.Pending',
        cellStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Pending,
        },
        headerStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Pending,
        },
      },
      {
        title: t('content.location_reviews.approved'),
        field: 'properties.locationsStatuses.Approved',
        cellStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Approved,
        },
        headerStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Approved,
        },
      },
      {
        title: t('content.location_reviews.rejected'),
        field: 'properties.locationsStatuses.Rejected',
        cellStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Rejected,
        },
        headerStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Rejected,
        },
      },
      {
        title: t('content.location_reviews.edited'),
        field: 'properties.locationsStatuses.Edited',
        cellStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Edited,
        },
        headerStyle: {
          backgroundColor: LOCATION_ROW_COLOUR_MAP.Edited,
        },
      },
      {
        title: t('content.location_reviews.year_since'),
        field: 'properties.yearStartedInField',
        render: (rowData: Provider) =>
          renderDate(rowData.properties.yearStartedInField, 'YYYY'),
      },
      {
        title: t('content.location_reviews.date'),
        field: 'created',
        defaultSort: 'desc',
        render: (rowData: Provider) => renderDate(rowData.created),
      },
    ];
  }, [t, renderDate]);

  const onClick = useCallback(
    (status: string) => {
      const statusSet = new Set(selectedStatuses);
      if (statusSet.has(status)) {
        statusSet.delete(status);
      } else {
        statusSet.add(status);
      }
      setSelectedStatuses(statusSet);
    },
    [setSelectedStatuses, selectedStatuses]
  );

  const locationsByFilter = useMemo<Provider[] | undefined>(() => {
    if (selectedStatuses.size < 1) {
      return providersWithResourceLocations;
    }

    return _.filter(
      providersWithResourceLocations,
      _fp.flow([
        _fp.get('properties.locations'),
        _fp.some((location: ResourceLocation) =>
          selectedStatuses.has(location.properties.approvalStatus || '')
        ),
      ])
    ) as Provider[];
  }, [providersWithResourceLocations, selectedStatuses]);

  const tableTitle = useMemo(() => {
    return `${t('content.location_reviews.title')} (${_.size(
      locationsByFilter
    )})`;
  }, [locationsByFilter, t]);

  return (
    <Stack flexGrow="1" spacing={2} paddingTop={2} paddingLeft={2}>
      <Stack paddingLeft={2} paddingRight={2}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" alignItems="center" spacing={3}>
            <Typography variant="h6">
              {t('content.location_reviews.filter')}
            </Typography>
            {_.map(ApprovalStatus, (status) => (
              <FormControlLabel
                key={status}
                control={
                  <Checkbox
                    checked={selectedStatuses.has(status)}
                    onClick={() => onClick(status)}
                  />
                }
                label={_.startCase(_.toLower(status))}
              />
            ))}
          </Stack>
          <LocationsExport locations={locationsByFilter} />
        </Stack>
        <div>
          <MaterialTable
            title={tableTitle}
            columns={columns}
            data={locationsByFilter || []}
            options={TABLE_OPTIONS}
            detailPanel={({ rowData: provider }) => (
              <DetailPanelContainer
                locations={provider.properties.locations}
                provider={provider}
              />
            )}
          />
        </div>
      </Stack>
    </Stack>
  );
}
