import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiLink'] = {
  root: {
    '.details-panel-link&': {
      wordWrap: 'break-word',
      width: '100%',
    },
  },
};

export const MuiLink = {
  styleOverrides,
};
