import { PropsWithChildren, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import { useConnectionState } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';

import Authenticating from '../components/Authenticating';
import Disconnected from '../components/Disconnected';
import Disconnecting from '../components/Disconnecting';
import Connecting from '../components/Connecting';
import Content from './Content';
import NotImplemented from '../components/NotImplemented';

import Header from '../components/Header';
import Footer from '../components/Footer';

function InternalMain(): JSX.Element {
  const connectionState = useConnectionState();

  return useMemo<JSX.Element>(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return <Disconnected />;

      case ConnectionService.ConnectionState.DISCONNECTING:
        return <Disconnecting />;

      case ConnectionService.ConnectionState.CONNECTING:
      case ConnectionService.ConnectionState.CONNECTING_FROM_TOKEN:
        return <Connecting />;

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        return <Content />;

      default:
        return <NotImplemented />;
    }
  }, [connectionState]);
}

function RouteElement({ children }: PropsWithChildren<any>): JSX.Element {
  return (
    <Stack height="100%">
      <Header />
      <Stack flexGrow="1">{children}</Stack>
      <Footer />
    </Stack>
  );
}

export default function Main(): JSX.Element {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/oidc"
          element={<RouteElement children={<Authenticating />} />}
        />

        <Route
          path="/*"
          element={<RouteElement children={<InternalMain />} />}
        />

        <Route
          path="*"
          element={<RouteElement children={<Navigate to="/" />} />}
        />
      </Routes>
    </Router>
  );
}
