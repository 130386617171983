import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LocationStatusButtonProps {
  onClick: () => void;
}

export default function LocationStatusButton({
  onClick,
}: LocationStatusButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      size="large"
      aria-label={t('content.location_reviews.approve_all')}
      variant="contained"
      onClick={onClick}
    >
      {t('content.location_reviews.approve_all')}
    </Button>
  );
}
