import { Stack, ThemeProvider } from '@mui/material';
import { useAppVersion, useTitleEffect } from 'hive-react-utils';
import { useEffect } from 'react';
import { DataContextProvider } from './contexts/DataContext';
import {
  CommonDataContextProvider,
  MessageBarProvider,
} from 'carefinder-shared-utilities';

import Main from './content/Main';
import { userTheme } from './theme';

export default function App() {
  useTitleEffect('app.title');

  const { version } = useAppVersion();
  useEffect(() => console.debug(`App Version: ${version}`), [version]);

  return (
    <ThemeProvider theme={userTheme}>
      <MessageBarProvider>
        <CommonDataContextProvider>
          <DataContextProvider>
            <Stack height="100%">
              <Main />
            </Stack>
          </DataContextProvider>
        </CommonDataContextProvider>
      </MessageBarProvider>
    </ThemeProvider>
  );
}
