import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'carefinder-shared-utilities';
import { MuiLink } from './link';

const updates: ThemeOptions = {
  components: {
    MuiLink,
  },
};

export const userTheme = createTheme(baseTheme, updates);
